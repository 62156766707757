import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import axios from "axios";
import moment from "moment";
import "vuetify/dist/vuetify.min.css";
import GmapVue from "gmap-vue";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.prototype.RGB = true;

axios.defaults.withCredentials = true;
if (
  window.location.host == "localhost:8080" ||
  window.location.host == "localhost:8081" ||
  window.location.hostname.substring(0, 9) == "192.168.1"
) {
  axios.defaults.baseURL = "http://192.168.1.8/hydrogest-api/ressources/";
} else {
  axios.defaults.baseURL = "ressources/";
}

axios.interceptors.response.use(undefined, function (error) {
  if (error && error.response) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/");
    }
  }
});

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyAqjsDIfm0O8JjNbTplRNnkJDEdeT5_xVk",
    libraries: "places",
  },
  installComponents: true,
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
