const state = {
  user: null,
  role: null,
  userInfo: null,
  date: null,
  light: false,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  StateRole: (state) => state.role,
  StateUserInfo: (state) => state.userInfo,
  StateLaDate: (state) => state.laDate,
  StateLight: (state) => state.light,
};

const actions = {
  async LogIn({ commit }, User) {
    commit("setUser", User.get("username"));
    commit("setRole", User.get("role"));
    commit("setUserInfo", User.get("userInfo"));
    commit("setLight", User.get("light"));
    commit("setLaDate", User.get("laDate"));
  },
  async newDate({ commit }, User) {
    commit("setLaDate", User.get("laDate"));
  },
  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setLaDate(state, laDate) {
    state.laDate = laDate;
  },
  setLight(state, light) {
    state.light = light;
  },
  setDark(state) {
    state.light = !state.light;
  },
  setUser(state, username) {
    state.user = username;
  },
  setRole(state, role) {
    state.role = role;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  logout(state) {
    state.user = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
