import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/utilisateurs",
    name: "utilisateurs",
    component: () => import("../views/Utilisateurs.vue"),
    meta: { requiresAuth: true, role: [1] },
  },
  {
    path: "/explorateur",
    name: "explorateur",
    component: () => import("../views/Explorateur.vue"),
    meta: { requiresAuth: true, role: [1, 2] },
  },
  {
    path: "/carte",
    name: "carte",
    component: () => import("../views/Carte.vue"),
    meta: { requiresAuth: true, role: [1, 2, 3] },
  },
  {
    path: "/bases",
    name: "bases",
    component: () => import("../views/Bases.vue"),
    meta: { requiresAuth: true, role: [1, 2] },
  },
  {
    path: "/groupes",
    name: "groupes",
    component: () => import("../views/Groupes.vue"),
    meta: { requiresAuth: true, role: [1, 2, 3] },
  },
  {
    path: "/hydrocheck",
    name: "hydrocheck",
    component: () => import("../views/Hydrocheck.vue"),
    meta: { requiresAuth: true, role: [1, 2] },
  },
  {
    path: "/hydrogest",
    name: "hydrogest",
    component: () => import("../views/Hydrogest.vue"),
    meta: { requiresAuth: true, role: [1] },
  },
  {
    path: "/cameras",
    name: "cameras",
    component: () => import("../views/Cameras.vue"),
    meta: { requiresAuth: true, role: [1, 2, 3] },
  },
  {
    path: "/centrales",
    name: "centrales",
    component: () => import("../views/Centrales.vue"),
    meta: { requiresAuth: true, role: [1, 2, 3] },
  },
  {
    path: "/informations",
    name: "informations",
    component: () => import("../views/Informations.vue"),
    meta: { requiresAuth: true, role: [1, 2, 3] },
  },
  {
    path: "/cryl",
    name: "cryl",
    component: () => import("../views/Cryl.vue"),
    meta: { requiresAuth: true, role: [1, 2] },
  },
  {
    path: "/mobile",
    name: "LoginMobile",
    component: () => import("../views/mobile/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/mobile/groupes",
    name: "GroupesMobile",
    component: () => import("../views/mobile/Groupes.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/mobile/utilisateurs",
    name: "UtilisateursMobile",
    component: () => import("../views/mobile/Utilisateurs.vue"),
    meta: { requiresAuth: true, role: [1] },
  },
  {
    path: "/mobile/carte",
    name: "CarteMobile",
    component: () => import("../views/mobile/Carte.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/mobile/centrales",
    name: "CentralesMobile",
    component: () => import("../views/mobile/Centrales.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/mobile/Informations",
    name: "InformationsMobile",
    component: () => import("../views/mobile/Informations.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/mobile/Cameras",
    name: "CamerasMobile",
    component: () => import("../views/mobile/Cameras.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //console.log(store.getters.StateRole);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      var role = to.meta.role;
      if (role.includes(parseInt(store.getters.StateRole))) {
        next();
        return;
      } else {
        next("/");
        return;
      }
    }
    next("/");
    return;
  } else {
    next();
    return;
  }
});

export default router;
