<template>
  <v-app>
    <v-bottom-navigation
      color="navBar"
      v-if="isLoggedIn && !isMobile"
      horizontal
    >
      <v-btn
        text
        color="navbarButton"
        to="/"
      ><span>Accueil</span> <v-icon>mdi-home</v-icon></v-btn>
      <v-btn
        class="RGB"
        to="/centrales"
      >
        <span>Centrales</span>
        <v-icon>mdi-hydro-power</v-icon>
      </v-btn>
      <v-btn
        class="RGB"
        to="/cameras"
      >
        <span>Caméras</span>
        <v-icon>mdi-cctv</v-icon>
      </v-btn>
      <v-btn
        class="RGB"
        to="/groupes"
      >
        <span>Groupes</span>
        <v-icon>mdi-account-tie</v-icon>
      </v-btn>
      <v-btn
        class="RGB"
        to="/carte"
      >
        <span>Carte</span>
        <v-icon>mdi-map</v-icon>
      </v-btn>
      <v-btn
        text
        color="navbarButton"
        v-if="isLoggedIn"
        @click="aLogout"
      >
        <span>{{ userInfo }}</span>
        <v-icon> mdi-logout</v-icon>
      </v-btn>
      <v-btn
        text
        color="navbarButton"
        v-else
        to="/"
      >
        <span>Connexion </span>
        <v-icon> mdi-login</v-icon>
      </v-btn>
      <v-btn @click="darkAndLight()">
        <v-img
          contain
          height="40"
          width="50"
          src="./assets/hydro.png"
        ></v-img>
      </v-btn>
    </v-bottom-navigation>
    <!-- If using vue-router -->
    <router-view
      v-if="isMobile && isLoggedIn"
      class="pb-7 mb-7"
    ></router-view>
    <router-view v-else></router-view>
    <v-dialog
      v-model="vLogout"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center">Se déconnecter ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="eLogout"
          >Non</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="logout"
          >Oui</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-navigation
      fixed
      color="navBar"
      v-if="isLoggedIn && isMobile"
      horizontal
    >
      <v-btn
        text
        color="navbarButton"
        to="/mobile"
      ><span>Accueil</span> <v-icon>mdi-home</v-icon></v-btn>
    </v-bottom-navigation>
  </v-app>
</template>
<script>
import axios from "axios";
import shared from "./shared";
import moment from "moment";
export default {
  name: "App",
  created () {
    this.isMobile = shared.isMobile();

    if (this.$store.getters.StateLight == 1) {
      this.$vuetify.theme.dark = true;
    }
    if (shared.isMobile() == true && this.isLoggedIn == true) {
      this.$vuetify.theme.dark = false;
    }
    this.checkDate();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    roleAdmin: function () {
      if (this.$store.getters.StateRole <= 1) return true;
      else return false;
    },
    roleTechnicien: function () {
      if (this.$store.getters.StateRole <= 2) return true;
      else return false;
    },
    userInfo: function () {
      return this.$store.getters.StateUserInfo;
    },
  },

  methods: {
    checkDate () {
      var vm = this;
      axios
        .post("dlcs/auth.php", {
          action: "checkDate",
          nomUtilisateur: this.$store.getters.StateUserInfo,
        })
        .then(function (response) {
          if (
            moment().format("YYYYMMDD") -
            moment(vm.$store.getters.StateLaDate).format("YYYYMMDD") >
            5 ||
            vm.$store.getters.StateLaDate == null
          ) {
            vm.logout();
          }
        });
    },
    colorClass () {
      if (this.$vuetify.theme.dark) {
        this.colorB = "rgba(255,255,255,0.7)";
        return this.colorB;
      } else {
        this.colorB = "rgba(0,0,0,0.6)";
        return this.colorB;
      }
    },
    darkAndLight () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit("setDark");
      axios
        .post("dlcs/auth.php", {
          action: "updateUserLight",
          light: this.$store.getters.StateLight,
          nomUtilisateur: this.$store.getters.StateUserInfo,
        })
        .then(function (response) {
          if (response.data.statut) {
          }
        });
    },
    menu (e) {
      if (e) {
        e.preventDefault();
        this.enterTheCode = true;
      }
    },
    eLogout () {
      this.vLogout = false;
    },
    aLogout () {
      this.vLogout = true;
    },
    async logout () {
      await this.$store.dispatch("LogOut");
      this.eLogout();
      this.$router.push("/").catch(() => { });
    },
  },
  data () {
    return {
      code: "",
      enterTheCode: false,
      timerCount: 5,
      vLogout: false,
      menuPremium: false,
      song: 0,
      account: false,
      audio: Audio,
      fond: 0,
      colorB: "",
      player: false,
      sent: false,
      cryl: false,
      subject: "",
      message: "",
      mobile: "",
    };
  },
};
</script>

<style>
.titreHydrogest {
  background-size: cover;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 4rem;
  text-align: center;
}
.helisa {
  background: rgb(216, 226, 146);
  background: linear-gradient(
    45deg,
    rgba(216, 226, 146, 1) 0%,
    rgba(116, 187, 153, 1) 40%,
    rgba(58, 159, 163, 1) 70%,
    rgba(31, 80, 121, 1) 100%
  );
}

.oui .v-btn__content {
  color: #ef5350;
  animation-name: RGB;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
.custom-color button {
  color: rgb(0, 255, 0) !important;
}
.custom-placeholer-color input::placeholder {
  color: rgb(0, 255, 0) !important;
  opacity: 1;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
    monospace;
}
.custom-placeholer-color input {
  color: rgb(0, 255, 0) !important;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
    monospace;
}
.custom-placeholer-color textarea {
  color: rgb(0, 255, 0) !important;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
    monospace;
}
.theme--light.v-data-table {
  border: 0.1rem solid rgb(225, 225, 225);
}
.v-progress-circular {
  margin: 1rem;
}
.RGB {
  color: #ef5350;
  animation-name: RGB;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes RGB {
  0% {
    color: #ef5350;
  }
  25% {
    color: #ffca28;
  }
  50% {
    color: #5c6bc0;
  }
  75% {
    color: #66bb6a;
  }
  100% {
    color: #ef5350;
  }
}
.caretRGB {
  caret-color: #ef5350;
  animation-name: caretRGB;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes caretRGB {
  0% {
    caret-color: #ef5350;
  }
  25% {
    caret-color: #ffca28;
  }
  50% {
    caret-color: #5c6bc0;
  }
  75% {
    caret-color: #66bb6a;
  }
  100% {
    caret-color: #ef5350;
  }
}
.backRGB {
  background-color: #ef5350;
  animation-name: backRGB;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes backRGB {
  0% {
    background-color: #ef5350;
  }
  25% {
    background-color: #ffca28;
  }
  50% {
    background-color: #5c6bc0;
  }
  75% {
    background-color: #66bb6a;
  }
  100% {
    background-color: #ef5350;
  }
}
.borderRGB {
  background-color: #ef5350;
  animation-name: backRGB;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes borderRGB {
  0% {
    border-color: #ef5350;
  }
  25% {
    border-color: #ffca28;
  }
  50% {
    border-color: #5c6bc0;
  }
  75% {
    border-color: #66bb6a;
  }
  100% {
    border-color: #ef5350;
  }
}
.rouge {
  background-color: #e57373;
  color: antiquewhite;
}
.vert {
  background-color: #81c784;
  color: antiquewhite;
}
.bleu {
  background-color: #818ec7;
  color: antiquewhite;
}
.alert {
  color: #da0400;
  animation-name: alert;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes alert {
  0% {
    color: #da0400;
  }
  25% {
    color: #0029b1;
  }
  50% {
    color: #da0400;
  }
  75% {
    color: #0029b1;
  }
  100% {
    color: #da0400;
  }
}

.backAlert {
  background-color: #da0400;
  animation-name: backAlert;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes backAlert {
  0% {
    background-color: #da0400;
  }
  25% {
    background-color: #0029b1;
  }
  50% {
    background-color: #da0400;
  }
  75% {
    background-color: #0029b1;
  }
  100% {
    background-color: #da0400;
  }
}

.theme--dark.v-tabs-items {
  background-color: #00000000 !important;
}

.v-toolbar__title:not(.infos) {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}
</style>
