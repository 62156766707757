import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        tabFond: "EEEEEE",
        boutonPlus: "000000",
        hydroweb: "0288D1",
        camera: "d500f9",
        routeur: "fb8c00",
        automate: "757575",
        service: "ff1744",
        map: "ffe000",
        base: "00e676",
        search: "000000",
        carteCouleur: "c50000",
        navbar: "D1D084",
        navbarButton: "666666",
      },
      dark: {
        tabFond: "424242",
        boutonPlus: "EEEEEE",
        hydroweb: "01579B",
        camera: "AB47BC",
        routeur: "EF6C00",
        automate: "777777",
        service: "df0b28",
        map: "d6bc00",
        base: "66BB6A",
        search: "FFFFFF",
        carteCouleur: "c58700",
        navbar: "FDF3D1",
        navbarButton: "c0c0c0",
      },
    },
  },
});
